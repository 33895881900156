import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS file for AOS

import MoveToTopButton from "../../components/moveToTopButton/MoveToTopButton";
import banner1 from "../../assets/images/meta-banner.webp";

export default function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000, // Animation duration
      offset: 50, // Offset (in pixels) from the original trigger point
      delay: 100, // Delay (in milliseconds) for the animation to start
    });
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    // return;
    try {
      const whatsappURL =
        "https://api.whatsapp.com/send?phone=+918923666030&text=";
      const formattedMessage = `Name: ${name}\nEmail: ${email}\nSubject: ${subject}\nMessage: ${message}`;
      const encodedMessage = encodeURIComponent(formattedMessage);
      const finalURL = whatsappURL + encodedMessage;

      // Redirect user to WhatsApp message URL
      window.location.href = finalURL;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Layout>
        <div className="">
          <div className="">
            <div
              className="md:h-[600px] h-[700px] w-full flex justify-start items-center bg-[#041324]"
              style={{
                background: `url(${banner1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="tracking-wide xl:w-[45%] lg:w-[65%] w-full text-white lg:ml-20 md:p-10 p-6">
                <h2 className="md:text-4xl text-3xl font-bold" style={{"lineHeight" : "45px"}} data-aos="fade-up">
                Get in Touch for Tailored Solutions
                </h2>
                <p className="mt-4" data-aos="fade-up">
                We’re here to help you achieve your business goals with our innovative technology services. Whether you have a question, need a consultation, or are ready to start a project, our team is just a message away.
                </p>
                <ul className=" list-disc mt-4" data-aos="fade-up">
                  <li>Personalized Consultations</li>
                  <li>Quick Response Times</li>
                  <li>Expert Guidance</li>
                  <li>Dedicated Support</li>
                </ul>
              </div>
              <div className=""></div>
            </div>
            <hr />
            <div className="">
              <h2 className="text-[40px] font-extrabold text-center mt-20 text-[#4e4d4d] leading-[50px]">
                Book a Free <br />{" "}
                <span className="text-[coral]">Consultation</span>
              </h2>
            </div>
            {/* Consultaion */}
            <div className="flex xl:flex-row flex-col items-center justify-around">
              <div className="lg:w-[45%] w-[96%] lg:h-[550px] h-auto flex flex-col justify-center lg:p-10 p-4">
                <div className="text-center mt-12 flex flex-col items-start justify-center">
                  {/* <p
                className="text-[#0085ff] text-[17px] font-medium mb-1"
                
              >
                Get In Touch
              </p> */}
                  <h2 className="md:text-4xl text-3xl font-medium tracking-wide text-black" data-aos="fade-up">
                    {" "}
                    What Set Us{" "}
                    <span className="font-bold text-[coral]" data-aos="fade-up">Apart</span>
                  </h2>
                  <p className="text-[#535353] text-start mt-4 tracking-wider md:w-[600px] w-[95%]" data-aos="fade-up">
                    Reach out today and let our experts provide you with
                    tailored solutions to drive your business forward.
                  </p>
                </div>
                <div className="flex gap-4 flex-col mt-10">
                  <div className="flex lg:flex-row flex-col flex-wrap md:gap-6 justify-between " data-aos="fade-up">
                    <div className="w-[200px] h-[120px]">
                      <h6 className="font-semibold text-xl">Address</h6>
                      <p className="mt-4">
                        Noida Sector 63 <br />
                        Greater Noida, Uttar Pradesh
                      </p>
                    </div>
                    <div className="w-[200px] h-[120px] md:mt-0 mt-6">
                      <h6 className="font-semibold text-xl">Working Hours</h6>
                      <p className="mt-4">
                        Monday to Friday: 9AM – 8PM <br />
                        Week-End: 10AM – 5PM
                      </p>
                    </div>
                  </div>
                  <div className="flex lg:flex-row flex-col flex-wrap md:gap-0 justify-between md:mt-0 mt-6" data-aos="fade-up">
                    <div className="w-[200px] h-[120px] lg:mt-1 mt-2">
                      <h6 className="font-semibold text-xl">Contact</h6>
                      <p className="mt-4">
                        innovixiontech@gmail.com <br />
                        +91 8923666030
                      </p>
                    </div>
                    <div className="w-[200px] h-[120px]">
                      <h6 className="font-semibold text-xl">Socials</h6>
                      <div className="mt-4 flex flex-row gap-4 text-2xl">
                        <Link to="" target="_blank">
                          {" "}
                          <i className="fa fa-facebook"></i>
                        </Link>
                        <Link to="" target="_blank">
                          {" "}
                          <i className="fa fa-linkedin"></i>
                        </Link>
                        <Link to="" target="_blank">
                          {" "}
                          <i className="fa fa-whatsapp"></i>
                        </Link>
                        <Link to="" target="_blank">
                          {" "}
                          <i className="fa fa-instagram"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-[50%] w-[96%] lg:h-[700px] h-auto flex flex-col justify-center md:p-16 p-4">
                <div className="">
                  <h2
                    className="text-[32px] font-semibold leading-[75px]"
                    data-aos="fade-left"
                  >
                    Get In <span className="font-bold text-[coral]">Touch</span>
                  </h2>
                </div>
                <div className="mt-4">
                  <form action="" onSubmit={sendMessage}>
                    <div className="flex md:flex-row flex-col md:gap-0 gap-3 justify-between">
                      <input
                        type="text"
                        className="h-[50px] md:w-[280px] w-full rounded-lg border border-[coral] shadow-sm  md:pl-5 pl-3 outline-none"
                        placeholder="Your Name"
                        data-aos="fade-left"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                      />
                      <input
                        type="text"
                        className="h-[50px] md:w-[280px] w-full rounded-lg border border-[coral] shadow-sm md:pl-5 pl-3 outline-none"
                        placeholder="Your Email"
                        data-aos="fade-left"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="mt-3">
                      <input
                        type="text"
                        className="h-[50px] w-full rounded-lg border border-[coral] shadow-sm md:pl-5 pl-3 outline-none"
                        placeholder="Your Subject"
                        data-aos="fade-left"
                        value={subject}
                        onChange={(e) => {
                          setSubject(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="mt-3">
                      <textarea
                        type="text"
                        className="h-[200px] w-full rounded-md border border-[coral] shadow-sm resize-none md:p-5 p-3 outline-none"
                        placeholder="Your Message"
                        data-aos="fade-left"
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                        required
                      ></textarea>
                    </div>
                    <div className="mt-2" data-aos="fade-left">
                      <button className="border-2 text-sm h-[40px] w-[160px] border-[coral] mt-6text-white bg-[coral] text-white tracking-wide">
                        Submit Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="text-center md:mt-0 mt-10">
              <Link
                to="/our-portfolio"
                className="text-[#041324] underline text-lg italic font-medium tracking-wide"
              >
                See what we build ?
              </Link>
            </div>
          </div>
        </div>
        <MoveToTopButton />
      </Layout>
    </div>
  );
}
