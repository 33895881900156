import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Homepage from '../pages/homepage/Homepage'
import HomePageSec from '../pages/homepage/HomePageSec'
import Services from '../pages/servicePage/Services'
import Portfolio from '../pages/portfolioPage/Portfolio'
import TeamPage from '../pages/teamPage/TeamPage'
import ContactUs from '../pages/contactUsPage/ContactUs'
import AboutUs from '../pages/aboutUsPage/AboutUs'

export default function AllRoutes() {
  return (
    <div>
      <Routes>
        {/* <Route path='/' element={<Homepage />}></Route> */}
        <Route path='/' element={<HomePageSec />}></Route>
        <Route path='/home' element={<Homepage />}></Route>
        <Route path='/our-services' element={<Services />}></Route>
        <Route path='/our-portfolio' element={<Portfolio />}></Route>
        <Route path='/our-team' element={<TeamPage />}></Route>
        <Route path='/contact-us' element={<ContactUs />}></Route>
        <Route path='/about-us' element={<AboutUs />}></Route>
      </Routes>
    </div>
  )
}
