import React from "react";
import googleBtn from "../../assets/images/googleplay_button.png";
import appleBtn from "../../assets/images/appstore_button.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      {/* Footer */}
      <div className="relative md:mt-20 mt-10">
        {/* <div class="custom-shape-divider-top-1721493287">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              class="shape-fill"
            ></path>
          </svg>
        </div> */}
        <div className="lg:h-[700px] h-full bg-[#0c111b] flex flex-col justify-center p-10">
          <div className="text-center">
            <p className="text-white" data-aos="fade-up">Have a Project To Discuss?</p>
            <h4 className="text-white font-bold text-4xl mt-2" data-aos="fade-up">We’re ready!</h4>
            <button className="bg-[coral] text-white hover:rounded-sm rounded-full text-[14px] md:text-base md:h-[42px] h-[36px] w-[140px] md:w-[180px] hover:bg-gradient-to-tr to-[coral] from-[#e78967] mt-5" data-aos="fade-up">
              Contact Us{" "}
            </button>
          </div>
          <div className="flex items-center mt-20">
            <div className="flex flex-wrap justify-around gap-16">
              <div className="lg:w-[25%] w-full">
                <h3 className="text-white font-bold text-2xl" data-aos="fade-up">
                  Innovixion Tech
                </h3>
                <p className="text-white text-[15px] mt-4" data-aos="fade-up">
                Innovixion Tech is a forward-thinking IT solutions provider dedicated to driving innovation and delivering tailored technology services. Our commitment to quality, reliability, and customer satisfaction defines who we are and drives everything we do.
                </p>
              </div>
              <div className="flex flex-wrap gap-20">
                <div className=" text-white">
                  <h6 className="text-2xl font-bold" data-aos="fade-up">QuickLinks</h6>
                  <ul>
                    <li className="font-medium mt-2" data-aos="fade-up"><Link to='/'>Home</Link></li>
                    <li className="font-medium mt-2" data-aos="fade-up"><Link to='/about-us'>About us</Link></li>
                    <li className="font-medium mt-2" data-aos="fade-up"><Link to='/our-team'>Our Team</Link></li>
                    <li className="font-medium mt-2" data-aos="fade-up"><Link to='/our-services'>Our Services</Link></li>
                  </ul>
                </div>
                <div className=" text-white">
                  <h6 className="text-2xl font-bold" data-aos="fade-up">Other Links</h6>
                  <ul>
                    <li className="font-medium mt-2" data-aos="fade-up"><Link to='/our-team'>What We Provide ?</Link></li>
                  <li className="font-medium mt-2" data-aos="fade-up"><Link to='/our-portfolio'>See Portfolio</Link></li>
                    <li className="font-medium mt-2" data-aos="fade-up"><Link to='/contact-us'>Get In Touch</Link></li>
                    <li className="font-medium mt-2" data-aos="fade-up"><Link to='/about-us'>About us</Link></li>
                  </ul>
                </div>
              </div>
              <div className="lg:w-[25%] w-full text-white">
                <h6 className="text-2xl font-bold" data-aos="fade-up">Download Our App</h6>
                <p className="text-white text-[15px] mt-4" data-aos="fade-up">
                  Available on Google Play and App Store.
                </p>
                <img
                  src={googleBtn}
                  alt=""
                  className="md:h-[40px] h-[36px] md:w-[120px] w-[100px] mt-6"
                   data-aos="fade-up"
                />
                <img
                  src={appleBtn}
                  alt=""
                  className="md:h-[40px] h-[36px] md:w-[120px] w-[100px] mt-6"
                   data-aos="fade-up"
                />
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-6 justify-between mt-10">
            <span className="text-white" data-aos="fade-up">© Copyright Innovixion Tech</span>
            <div className="flex gap-6" data-aos="fade-up">
              <Link>
              <div className="h-[50px] w-[50px] rounded-full flex justify-center items-center bg-white">
                <i className="fa fa-facebook"></i>
              </div></Link>
              <Link><div className="h-[50px] w-[50px] rounded-full flex justify-center items-center bg-white">
                <i className="fa fa-linkedin"></i>
              </div></Link>
              <Link>
              <div className="h-[50px] w-[50px] rounded-full flex justify-center items-center bg-white">
                <i className="fa fa-instagram"></i>
              </div>
              </Link>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
