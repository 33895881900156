import React, { useEffect, useState } from "react";
import logo from "../../assets/images/IT_logo.png";
import menuButton from "../../assets/images/menubar.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Navbar() {
  const [navbarColor, setNavbarColor] = useState("bg-transparent");
  const navigate = useNavigate("");

  const [toggleNav, setToggleNav] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;

  const handleToggleNav = () => {
    setToggleNav(!toggleNav);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 60) {
        setNavbarColor("bg-white");
      } else {
        setNavbarColor("bg-transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`py-3 md:px-16 px-6 flex justify-between items-center fixed ${navbarColor} top-0 left-0 z-20 w-full ${
          navbarColor === "bg-white" ? "text-black" : "text-white"
        } transition-all`}
      >
        <div className="flex items-center gap-12">
          <div className="">
            <img src={logo} alt="" className="h-[52px] w-[50px]" />
          </div>
          <div>
            <ul className="lg:flex justify-around gap-10 hidden">
              <li
                className={`text-[14px] font-semibold tracking-wide cursor-pointer ${
                  currentPath === "/" ? "text-[coral]" : ""
                }`}
              >
                <Link to="/">HOME</Link>
              </li>
              <li
                className={`text-[14px] font-semibold tracking-wide cursor-pointer ${
                  currentPath === "/our-services" ? "text-[coral]" : ""
                }`}
              >
                <Link to="/our-services">SERVICES</Link>
              </li>
              <li
                className={`text-[14px] font-semibold tracking-wide cursor-pointer ${
                  currentPath === "/our-portfolio" ? "text-[coral]" : ""
                }`}
              >
                <Link to="/our-portfolio">PORTFOLIO</Link>
              </li>
              <li
                className={`text-[14px] font-semibold tracking-wide cursor-pointer ${
                  currentPath === "/our-team" ? "text-[coral]" : ""
                }`}
              >
                <Link to="/our-team">OUR TEAM</Link>
              </li>
              <li
                className={`text-[14px] font-semibold tracking-wide cursor-pointer ${
                  currentPath === "/contact-us" ? "text-[coral]" : ""
                }`}
              >
                <Link to="/contact-us">CONTACT US</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex items-end gap-8">
          <div className="lg:block hidden">
            <button
              className="bg-[coral] h-[38px] w-[120px] rounded-full text-white tracking-wider text-sm"
              onClick={() => {
                navigate("/about-us");
              }}
            >
              About Us
            </button>
          </div>
          <div className="block lg:hidden">
            <div className="relative lg:top-0 md:mr-0 mr-3">
              <button onClick={handleToggleNav}>
                {toggleNav ? (
                  <i className="fa fa-bars text-2xl"></i>
                ) : (
                  <i className="fa fa-bars text-2xl"></i>
                )}
              </button>
            </div>
            {
              <div
                className={`absolute right-0 -top-9 bg-[#011c29] ${
                  toggleNav ? "w-[250px]" : "w-[0px]"
                } transition-all mt-[34px] shadow-md h-[100vh] duration-300 overflow-hidden whitespace-nowrap tracking-wider`}
              >
                <div className="text-end relative top-4 right-8">
                  <button onClick={handleToggleNav}>
                    <i className="fa fa-times text-2xl text-white"></i>
                  </button>
                </div>
                <ul className="flex flex-col justify-around gap-6 text-[coral] px-6 py-10 font-medium">
                  <li
                    className={`cursor-pointer hover:text-[white] border-[white]  text-center pb-2 border-b ${
                      currentPath === "/" ? "text-[white]" : ""
                    }`}
                  >
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className={`cursor-pointer hover:text-[white] border-[white]  text-center pb-2 border-b ${
                      currentPath === "/our-services" ? "text-[white]" : ""
                    }`}
                  >
                    <Link to="/our-services">Services</Link>
                  </li>
                  <li
                    className={`cursor-pointer hover:text-[white] border-[white]  text-center pb-2 border-b ${
                      currentPath === "/our-portfolio" ? "text-[white]" : ""
                    }`}
                  >
                    <Link to="/our-portfolio">Projects</Link>
                  </li>
                  <li
                    className={`cursor-pointer hover:text-[white] border-[white]  text-center pb-2 border-b ${
                      currentPath === "/our-team" ? "text-[white]" : ""
                    }`}
                  >
                    <Link to="/our-team">Our Team</Link>
                  </li>
                  <li
                    className={`cursor-pointer hover:text-[white] border-[white]  text-center pb-2 border-b ${
                      currentPath === "/about-us" ? "text-[white]" : ""
                    }`}
                  >
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li
                    className={`cursor-pointer hover:text-[white] border-[white]  text-center pb-2 border-b ${
                      currentPath === "/contact-us" ? "text-[white]" : ""
                    }`}
                  >
                    <Link to="/contact-us">Contact us</Link>
                  </li>
                </ul>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
