import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS file for AOS

import MoveToTopButton from "../../components/moveToTopButton/MoveToTopButton";
import banner1 from "../../assets/images/saas_banner.webp";
import feature1 from "../../assets/images/featured1.png";
import feature2 from "../../assets/images/featured2.png";
import feature3 from "../../assets/images/featured3.png";
import feature4 from "../../assets/images/featured4.png";
import feature5 from "../../assets/images/featured5.png";
import feature6 from "../../assets/images/featured6.png";

export default function AboutUs() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000, // Animation duration
      offset: 50, // Offset (in pixels) from the original trigger point
      delay: 100, // Delay (in milliseconds) for the animation to start
    });
  }, []);

  return (
    <div>
      <Layout>
        <div className="">
          <div className="">
            <div
              className="md:h-[600px] h-[700px] w-full flex justify-start items-center bg-[#041324]"
              style={{
                background: `url(${banner1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "rigth",
              }}
            >
              <div className="tracking-wide xl:w-[45%] lg:w-[65%] w-full text-white lg:ml-20 md:p-10 p-6">
                <h2 className="md:text-4xl text-3xl font-bold" style={{"lineHeight" : "45px"}} data-aos="fade-up">
                  Driving Innovation with a Vision for the Future
                </h2>
                <p className="mt-4" data-aos="fade-up">
                  At Innovixion Tech, we are more than just a technology
                  company; we are a team of innovators committed to transforming
                  businesses through cutting-edge solutions. Our journey is
                  built on a foundation of trust, expertise, and a relentless
                  pursuit of excellence.
                </p>
                <ul className=" list-disc mt-4" data-aos="fade-up">
                  <li>Visionary Leadership</li>
                  <li>Client-Centric Approach</li>
                  <li>Proven Track Record</li>
                  <li>Commitment to Quality</li>
                </ul>
              </div>
              <div className=""></div>
            </div>
            <hr />

            <div className="">
              <h2 className="md:text-[38px] text-3xl font-extrabold text-center mt-20 text-[#4e4d4d]" data-aos="fade-up">
                We're Highlighted In
              </h2>
              <div className="mt-16 flex flex-wrap lg:gap-16 gap-10 justify-center">
                <i class="devicon-flutter-plain colored text-[65px]" data-aos="fade-up"></i>
                <i class="devicon-mongodb-plain colored text-[65px]" data-aos="fade-up"></i>
                <i class="devicon-express-original colored text-[65px]" data-aos="fade-up"></i>
                <i class="devicon-react-original colored text-[65px]" data-aos="fade-up"></i>
                <i class="devicon-nodejs-plain-wordmark colored text-[65px]" data-aos="fade-up"></i>
                <i class="devicon-php-plain colored text-[65px]" data-aos="fade-up"></i>
                <i class="devicon-android-plain colored text-[65px]" data-aos="fade-up"></i>
              </div>
            </div>

            <div className="mt-28 md:px-20 px-6">
              <h2 className="md:text-[40px] text-3xl font-extrabold text-center text-[#4e4d4d]" data-aos="fade-up">
                Story Behind Our Firm
              </h2>
              <div className="mt-10  tracking-wide">
                <p className="text-[#424242]" data-aos="fade-up">
                  Innovixion Tech was born out of a simple yet powerful idea: to
                  revolutionize the way businesses interact with technology. Our
                  journey began in 2022 when a group of passionate tech
                  enthusiasts, each with a unique background in software
                  development, IT consulting, and digital innovation, came
                  together with a shared vision. They saw how technology was
                  rapidly transforming industries but noticed a gap between what
                  businesses needed and what was being offered.Over the years,
                  Innovixion Tech has grown into a dynamic team of experts in
                  various fields, including software development, digital
                  marketing, cloud computing, and IT strategy. Our growth has
                  never led us to lose sight of our roots. We remain dedicated
                  to the principles that inspired our founding: a relentless
                  pursuit of innovation, a deep understanding of our clients’
                  needs, and a commitment to delivering solutions that drive
                  real, measurable success.
                </p>
                <p className="text-[#424242] mt-4" data-aos="fade-up">
                  Starting as a small startup in a modest office, we poured our
                  energy into understanding the specific needs of our clients.
                  Our first major project—a comprehensive digital transformation
                  for a local business—was a resounding success and set the tone
                  for the years to come. This success was not just about
                  delivering a product; it was about creating value, building
                  trust, and forging lasting partnerships.
                </p>
              </div>
            </div>

            <div className="mt-28">
              <h2 className="md:text-[40px] text-3xl font-extrabold text-center text-[#4e4d4d]" data-aos="fade-up">
                Our Clients
              </h2>
              <div className="">
                <div className="mt-16 flex flex-wrap xl:gap-20 lg:gap-16 gap-10 justify-center">
                  <img
                    src={feature1}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                  <img
                    src={feature2}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                  <img
                    src={feature3}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                  <img
                    src={feature4}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                  <img
                    src={feature5}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                  <img
                    src={feature6}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                  <img
                    src={feature1}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                  <img
                    src={feature2}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                  <img
                    src={feature3}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                  <img
                    src={feature4}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                  <img
                    src={feature5}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                  <img
                    src={feature6}
                    alt=""
                    className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                  />
                </div>
              </div>
            </div>

            <div className="text-center my-16" data-aos="fade-up">
              <Link
                to="/contact-us"
                className="text-[coral] underline text-lg italic font-medium tracking-wide"
              >
                Want to talk about your undertaking?
              </Link>
            </div>
          </div>
        </div>
        <MoveToTopButton />
      </Layout>
    </div>
  );
}
