import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Import Swiper styles
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "../../styles/parallax.css";

// Import Swiper styles
import "swiper/swiper-bundle.css";

import { Autoplay, Pagination } from "swiper/modules"; // Correct import path

import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS file for AOS

import videoBg from "../../assets/videos/home-banner.mp4";
import Layout from "../../components/layout/Layout";
import slider1 from "../../assets/images/nextgen-banner-1.webp";
import slider2 from "../../assets/images/nextgen-banner-2.webp";
import slider3 from "../../assets/images/nextgen-banner-3.webp";
import project2 from "../../assets/images/project-2.webp";
import project3 from "../../assets/images/project-3.webp";
import project4 from "../../assets/images/project-4.webp";
import project5 from "../../assets/images/project-5.webp";
import company1 from "../../assets/images/company1.webp";
import company2 from "../../assets/images/company2.webp";
import company3 from "../../assets/images/company3.webp";
import company4 from "../../assets/images/company4.webp";
import homeBannerPoster from '../../assets/images/home-banner-poster.webp'
import { useNavigate } from "react-router-dom";
import MoveToTopButton from "../../components/moveToTopButton/MoveToTopButton";

// const sliders = [
//   {
//     title: "Nature's Beauty",
//     description:
//       "Immerse yourself in the stunning landscapes, from lush forests to majestic mountains. Discover the tranquility and splendor that nature offers, perfect for those who seek peace and inspiration in the great outdoors.",
//     bgImg: slider1,
//     link: "https://example.com/nature",
//   },
//   {
//     title: "Urban Exploration",
//     description:
//       "Uncover the secrets of the city, where modern architecture meets historical landmarks. Delve into the vibrant culture, bustling streets, and hidden spots that make urban life so fascinating and dynamic.",
//     bgImg: slider2,
//     link: "https://example.com/urban",
//   },
//   {
//     title: "Tech Innovations",
//     description:
//       "Stay ahead with the latest in technology, from groundbreaking gadgets to emerging trends. Explore how innovations are shaping the future, offering new possibilities and transforming the way we live and work.",
//     bgImg: slider3,
//     link: "https://example.com/tech",
//   },
// ];

const sliders = [
  {
    title: "AI-Powered Solutions",
    description:
      "Unlock the potential of Artificial Intelligence with advanced algorithms that drive innovation, efficiency, and smart decision-making. From predictive analytics to intelligent automation, explore how AI is enhancing productivity, and redefining the future of technology.",
    bgImg: slider1,
    link: "/contact-us",
  },
  {
    title: "Cloud Computing Revolution",
    description:
      "Harness the power of cloud computing to achieve unparalleled scalability, robust security, and ultimate flexibility. Discover how cloud technology is enabling businesses to operate more efficiently, reduce costs, and stay competitive in a global & digital landscape",
    bgImg: slider2,
    link: "/contact-us",
  },
  {
    title: "Cybersecurity Enhancements",
    description:
      "Protect your digital assets with cutting-edge cybersecurity solutions designed to counteract even the most sophisticated threats. Stay ahead in an ever-evolving landscape with advanced security measures, ensuring the integrity, confidentiality, and availability of your critical data in an increasingly connected world.",
    bgImg: slider3,
    link: "/contact-us",
  },
];

const services = [
  {
    title: "Web Development",
    description:
      "Our team creates responsive and engaging websites tailored to your business needs, ensuring optimal user experience and performance across all devices.",
    link: "/services/web-development",
  },
  {
    title: "Mobile App Development",
    description:
      "We design and develop custom mobile applications for iOS and Android, providing seamless functionality and a great user experience on all devices.",
    link: "/services/mobile-app-development",
  },
  {
    title: "Digital Marketing",
    description:
      "Our digital marketing services include SEO, SEM, social media marketing, and content creation to boost your online presence and attract more customers.",
    link: "/services/digital-marketing",
  },
  {
    title: "Graphic Design",
    description:
      "We offer professional graphic design services, including branding, logo creation, and promotional materials, to help your business stand out visually.",
    link: "/services/graphic-design",
  },
  {
    title: "Consulting",
    description:
      "Our consulting services provide expert guidance and strategies to help you optimize your business processes, improve efficiency, and achieve your goals.",
    link: "/services/consulting",
  },
  {
    title: "Cloud Solutions",
    description:
      "We offer cloud solutions that include cloud storage, cloud computing, and cloud-based applications to enhance your business scalability and flexibility.",
    link: "/services/cloud-solutions",
  },
];

export default function HomePageSec() {
  const [faqs, setFaqs] = useState([
    {
      question: "What types of businesses do you work with?",
      answer:
        "We collaborate with a diverse range of businesses, from startups and small to medium-sized enterprises (SMBs) to large corporations. Our tailored solutions cater to various industry needs, including custom software development, digital marketing, and enterprise software solutions. Whether you're looking for rapid prototyping or comprehensive digital transformation, we have the expertise to help you achieve your goals.",
    },
    {
      question: "What makes your development process unique?",
      answer:
        "Our development process stands out due to our commitment to agile methodologies and client collaboration. We emphasize iterative development, allowing for continuous feedback and adjustments to ensure the final product meets your exact needs. Our approach includes detailed project planning, regular progress updates, and thorough quality assurance to deliver high-quality solutions efficiently and effectively.",
    },
    {
      question: "How do you handle project changes or scope adjustments?",
      answer:
        "We understand that project needs can evolve over time. Our agile approach is designed to accommodate changes and scope adjustments seamlessly. We work closely with you to assess the impact of any modifications, adjust timelines and resources as necessary, and ensure that the final deliverables align with your updated requirements. Our goal is to maintain flexibility while delivering a product that meets your expectations.",
    },
    {
      question: "What is your approach to ensuring data privacy and security?",
      answer:
        "Data privacy and security are top priorities for us. We implement comprehensive security measures, including data encryption, secure access controls, and regular security audits, to protect your sensitive information. Additionally, we comply with industry standards and regulations to ensure that your data is handled responsibly and securely throughout the project lifecycle and beyond.",
    },
    {
      question:
        "Can you provide examples of successful projects or case studies?",
      answer:
        "Absolutely! We have a portfolio of successful projects and case studies that highlight our expertise and the results we've achieved for our clients. From developing custom software solutions for startups to implementing enterprise-level systems for large organizations, our case studies demonstrate our ability to deliver impactful results. Contact us to request specific examples that match your industry or project needs.",
    },
  ]);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate("");

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000, // Animation duration
      offset: 50, // Offset (in pixels) from the original trigger point
      delay: 100, // Delay (in milliseconds) for the animation to start
    });
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % services.length);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  return (
    <div>
      <Layout>
        <MoveToTopButton />
        <div className="">
          {/* Homepage Section */}
          <div className="lg:h-[100vh] h-[650px] w-full">
            <div className="relative lg:h-screen h-[650px] w-full">
              <video
                className="absolute top-0 left-0 h-full w-full object-cover"
                src={videoBg}
                autoPlay
                loop
                muted
                playsInline
                poster={homeBannerPoster}
              />
              <div className="relative z-10 flex items-center lg:justify-start justify-center h-full bg-[rgba(62,61,61,0.5)] lg:pl-20 md:px-12 px-6">
                <div
                  className="lg:w-[850px] w-full relative md:-top-8 mt-20"
                >
                  <p className="text-[coral] tracking-wider font-bold text-[18px] mb-2" data-aos="fade-up">
                    Welcome to Innovixion Tech Private Limited
                  </p>
                  <h1 className="lg:text-[60px] md:text-4xl text-3xl lg:font-bold font-semibold lg:leading-[65px] leading-[40px] tracking-wide text-white" data-aos="fade-up">
                    Innovative IT Solutions for Growth & Development.
                  </h1>
                  <p className="text-[white] mt-6 tracking-wider font-medium" data-aos="fade-up">
                    At Innovixion Tech, we specialize in transforming your
                    technological challenges into opportunities for growth and
                    success. With extensive experience in the IT sector, we
                    bring unparalleled expertise, innovation, and dedication to
                    every project.
                  </p>
                  <div className="flex flex-col md:flex-row gap-4 mt-6" data-aos="fade-up">
                    <button
                      className="bg-[coral] text-white rounded-sm text-[14px] md:text-base md:h-[42px] h-[36px] w-[140px] md:w-[180px] hover:bg-gradient-to-tr to-[coral] from-[#e78967]"
                      onClick={() => {
                        navigate("/contact-us");
                      }}
                    >
                      Contact Us{" "}
                    </button>
                    {/* <button className="border-[grey] border-2 rounded-sm md:h-[38px] h-[36px] text-[14px] md:text-base w-[140px] md:w-[150px] flex gap-2 items-center justify-center">
                  Learn Now{" "}
                  <img
                    src={rightIcon}
                    className="h-[20px] w-[20px]"
                    alt=""
                  />
                </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Number */}
          <div className="flex md:flex-row flex-col flex-wrap justify-center items-center w-full lg:gap-20 gap-10 my-10" data-aos="fade-up">
            <div className="flex items-center w-[230px] tracking-wide">
              <h6 className="lg:text-[60px] text-[50px] text-[coral] font-extrabold">5k</h6>
              <p className="lg:text-2xl text-xl text-[grey] font-medium ml-6">
                LinkedIn Followers
              </p>
            </div>
            <div className="h-[100px] border border-[grey] md:block hidden"></div>
            <div className="w-[100px] border border-[grey] block md:hidden"></div>
            <div className="flex items-center w-[230px] tracking-wide">
              <h6 className="lg:text-[60px] text-[50px] text-[coral] font-extrabold">4.8</h6>
              <p className="lg:text-2xl text-xl text-[grey] font-medium ml-6">
                Ratings of Customers
              </p>
            </div>
            <div className="h-[100px] border border-[grey] md:block hidden"></div>
            <div className="w-[100px] border border-[grey] block md:hidden"></div>
            <div className="flex items-center w-[230px] tracking-wide">
              <h6 className="lg:text-[60px] text-[50px] text-[coral] font-extrabold">25</h6>
              <p className="lg:text-2xl text-xl text-[grey] font-medium ml-6">
                Number of Projects
              </p>
            </div>
          </div>

          {/* Sliders */}
          <div className="lg:mt-28 md:mt-24 mt-10 flex justify-center">
            <div className="lg:w-[80%] w-[90%]">
              <div className="text-start">
                <h2 className="text-[#011c29] md:text-4xl text-3xl font-extrabold" data-aos="fade-up">
                Unlocking Potential with Cutting-Edge Technology
                </h2>
              </div>

              <div className="mt-12 xl:w-[1100px] w-full lg:h-[400px] md:h-[400px] h-[500px] m-auto" data-aos="fade-up">
                <Swiper
                  modules={[Autoplay, Pagination]} // Register the modules
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  loop={true} // Enables looping
                  autoplay={{
                    delay: 3000, // Time between slides in milliseconds
                    disableOnInteraction: false, // Continue autoplay after user interactions
                  }}
                  className="mySwiper"
                >
                  {sliders.map((slider, index) => {
                    return (
                      <SwiperSlide>
                        <div
                          className="h-[500px] bg-contain rounded-2xl border"
                          style={{ background: `url(${slider.bgImg})` }}
                        >
                          <div className="lg:w-[70%] w-[90%] flex flex-col justify-center h-full lg:p-12 md:p-10 p-6 py-10">
                            <h3 className="text-white md:text-4xl text-2xl font-semibold">
                              {slider.title}
                            </h3>
                            <p className="text-white md:mt-6 mt-4">
                              {slider.description}
                            </p>
                            <button className="bg-[coral] text-white rounded-full text-[14px] md:text-base md:h-[40px] h-[36px] w-[140px] md:w-[180px] hover:bg-gradient-to-tr to-[coral] from-[#e78967] mt-6" onClick={()=>{
                              navigate('/our-services')
                            }}>
                              See More{" "}
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>

          {/* Core Services */}
          <div className=" flex justify-center items-center md:mt-44 mt-20">
            <div className="lg:w-[80%] w-[90%]">
              <div className="">
                <h3 className="md:text-4xl text-3xl text-[#011c29] font-extrabold" data-aos="fade-up">
                  Core Services We Provide
                </h3>
              </div>
              {/* <div className="mt-20 flex flex-wrap gap-8 justify-between">
                {services.map((service, index) => {
                  return (
                    <div className="w-[350px] px-6 py-4 group bg-white hover:bg-[coral] hover:rounded-md">
                      <div className="">
                        <i className="fa fa-code text-5xl font-bold text-[coral] group-hover:text-white"></i>
                      </div>
                      <div className="mt-4 font-medium">
                        <h4 className="text-xl font-semibold tracking-wide group-hover:text-white">
                          {service.title}
                        </h4>
                        <p className="mt-2 group-hover:text-white">{service.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div> */}
              <div className="md:mt-20 mt-16 flex flex-wrap gap-8 md:justify-between justify-center items-center">
                {services.map((service, index) => (
                  <div
                    key={index}
                    className={`w-[350px] px-6 py-4 transition-all duration-300 ${
                      index === activeIndex
                        ? "bg-[coral] text-white"
                        : "bg-white text-[#011c29]"
                    } rounded-md`}
                     data-aos="fade-up"
                  >
                    <div className="">
                      <i
                        className={`fa fa-code text-5xl font-bold ${
                          index === activeIndex ? "text-white" : "text-[coral]"
                        }`}
                      ></i>
                    </div>
                    <div className="mt-4 font-medium">
                      <h4
                        className={`text-xl font-semibold tracking-wide ${
                          index === activeIndex
                            ? "text-white"
                            : "text-[#011c29]"
                        }`}
                      >
                        {service.title}
                      </h4>
                      <p
                        className={`mt-2 ${
                          index === activeIndex
                            ? "text-white"
                            : "text-[#011c29]"
                        }`}
                      >
                        {service.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Parallax */}
          <div className="mt-20">
            <div className="relative">
              <div className="parallax">
                <div className="flex flex-col items-center justify-center h-full w-full bg-black bg-opacity-50 text-white p-10 text-center">
                  <h2 className="md:text-4xl text-3xl font-bold" data-aos="fade-up">
                    Gain Digital Edge, Accelerate Growth
                  </h2>
                  <p className="mt-4 text-lg tracking-wide" data-aos="fade-up">
                    Let's discuss how our end-to-end solutions can drive growth
                    for your industry. Book a free consultation now.
                  </p>
                  <button className="bg-[coral] text-white rounded-sm text-[14px] md:text-base md:h-[42px] h-[36px] w-[140px] md:w-[180px] hover:bg-gradient-to-tr to-[coral] from-[#e78967] mt-6" onClick={()=>{
                    navigate('/contact-us')
                  }}  data-aos="fade-up">
                    Contact Us{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Expertise Services */}
          {/* <div className=" flex justify-center mt-28">
            <div className="w-[80%]">
              <div className="">
                <h3 className="text-4xl text-[#5c5c5c] font-extrabold">
                  Expertise Across Domains
                </h3>
              </div>
              <div className="mt-16 flex flex-wrap gap-10 justify-between">
                {services.map((service, index) => {
                  return (
                    <div className="w-[300px]">
                      <div className="">
                        <i className="fa fa-code text-5xl font-bold text-[coral]"></i>
                      </div>
                      <div className="mt-4 font-medium">
                        <h4 className="text-xl font-semibold tracking-wide">
                          {service.title}
                        </h4>
                        <p className="mt-2">{service.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div> */}

          {/* Projects */}
          <div className=" flex justify-center md:mt-44 mt-28">
              <div className="lg:w-[90%] w-full">
                <div className="flex lg:flex-row flex-col items-center gap-20 justify-between">
                  <div className="lg:w-[52%] w-[90%] flex justify-center items-center" data-aos="fade-up">
                    <img
                      src={project2}
                      className="lg:h-[500px] md:h-[360px] h-[320px]"
                      alt=""
                    />
                  </div>
                  <div className="lg:w-[48%] md:w-[80%] w-[90%]" data-aos="fade-up">
                    <h3 className="text-4xl font-bold">
                      AMJ Workplace Wellness
                    </h3>
                    <div className="flex flex-wrap gap-4 font-medium mt-6 text-sm">
                      <span className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee]">
                        Game Design
                      </span>
                      <span className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[pink]">
                        Mobile App Development
                      </span>
                    </div>
                    <p className="mt-4 tracking-wide">
                      AMJ Workplace Wellness is a holistic wellness app designed
                      to promote employee health and fitness in the corporate
                      environment.
                    </p>
                    <div className="flex flex-col justify-center items-start gap-0 mt-8">
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        Seamless UI/UX Design for Corporate Use
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        Integration with Wearable Devices
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        Real-time Health Tracking and Analysis
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        80% Increase in Employee Satisfaction
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        AI-driven Personalized Recommendations
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col-reverse items-center gap-20 justify-between md:mt-36 mt-20">
                  <div className="lg:w-[48%] md:w-[80%] w-[90%]" data-aos="fade-up">
                    <h3 className="text-4xl font-bold">Truth GPT</h3>
                    <div className="flex flex-wrap gap-4 font-medium mt-6 text-sm">
                      <span className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee]">
                        UI/UX Design
                      </span>
                      <span className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[pink]">
                        Mobile App Development
                      </span>
                    </div>
                    <p className="mt-4 tracking-wide">
                      AI-powered mobile application designed for transparent,
                      accessible information through a chatbot interface.
                      Developed to enhance interaction realism with advanced
                      Flutter animations, engaging 3D models, and prompt
                      contextual engineering for quick, helpful responses.
                    </p>
                    <div className="flex flex-col justify-center items-start gap-0 mt-8">
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        Seamless UI/UX Design for Corporate Use
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        Integration with Wearable Devices
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        Compatible with Other Devices
                      </div>
                      <br />
                    </div>
                  </div>
                  <div className="lg:w-[52%] w-[90%] flex justify-center items-center" data-aos="fade-up">
                    <img
                      src={project3}
                      className="lg:h-[500px] md:h-[380px] h-[330px]"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col items-center gap-20 justify-between md:mt-36 mt-20">
                  <div className="lg:w-[52%] w-[90%] flex items-center justify-center" data-aos="fade-up">
                    <img
                      src={project4}
                      className="lg:h-[360px] md:h-[280px] h-[240px]"
                      alt=""
                    />
                  </div>
                  <div className="lg:w-[48%] md:w-[80%] w-[90%]" data-aos="fade-up">
                    <h3 className="text-4xl font-bold">Find Serve & Keep</h3>
                    <div className="flex flex-wrap gap-4 font-medium mt-6 text-sm">
                      <span className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee]">
                        Game Design
                      </span>
                      <span className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[pink]">
                        Mobile App Development
                      </span>
                    </div>
                    <p className="mt-4 tracking-wide">
                      FSK revolutionizes mobile gaming with its innovative
                      fusion of match-3 puzzles, time management challenges, and
                      captivating role-playing elements.
                    </p>
                    <div className="flex flex-col justify-center items-start gap-0 mt-8">
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        Seamless UI/UX Design for Corporate Use
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        Integration with Wearable Devices
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        Real-time Health Tracking and Analysis
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        AI-driven Personalized Recommendations
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col-reverse items-center gap-20 justify-between md:mt-36 mt-20">
                  <div className="lg:w-[48%] md:w-[80%] w-[90%]" data-aos="fade-up">
                    <h3 className="text-4xl font-bold">Super App Yeppy</h3>
                    <div className="flex flex-wrap gap-4 font-medium mt-6 text-sm">
                      <span className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee]">
                        UI/UX Design
                      </span>
                      <span className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[pink]">
                        Mobile App Development
                      </span>
                    </div>
                    <p className="mt-4 tracking-wide">
                      AI-powered mobile application designed for transparent,
                      accessible information through a chatbot interface.
                      Developed to enhance interaction realism with advanced
                      Flutter animations, engaging 3D models, and prompt
                      contextual engineering for quick, helpful responses.
                    </p>
                    <div className="flex flex-col justify-center items-start gap-0 mt-8">
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        30% increase in user engagement within the first month
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        Integration with Wearable Devices
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        Real-time Health Tracking and Analysis
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full text-sm md:text-base bg-[#eeeeee] border -mt-1">
                        80% Increase in Employee Satisfaction
                      </div>
                      <br />
                    </div>
                  </div>
                  <div className="lg:w-[52%] w-[90%] flex items-center justify-center" data-aos="fade-up">
                    <img
                      src={project5}
                      className="lg:h-[500px] md:h-[380px] h-[330px]"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

          {/* Banner 3 */}
          <div className="bg-[#324251] h-[620px] mt-36 flex lg:flex-row flex-col-reverse justify-around items-center py-6 px-2">
            <div className="lg:w-[45%] w-[90%] ml-10">
              <h3 className="lg:text-5xl md:text-4xl text-3xl text-[white] tracking-wide md:leading-[60px] leading-[42px] font-bold lg:w-[700px] w-full" data-aos="fade-up">
                Cultivating Strategic Partnerships for Business Growth
              </h3>
            </div>
            <div className="lg:w-[40%] w-[90%] flex gap-10 flex-wrap justify-center">
              <img src={company1} alt="" className="lg:h-[200px] md:h-[150px] h-[120px] lg:w-[200px] md:w-[150px] w-[120px]"  data-aos="fade-up" />
              <img src={company2} alt="" className="lg:h-[200px] md:h-[150px] h-[120px] lg:w-[200px] md:w-[150px] w-[120px]"  data-aos="fade-up" />
              <img src={company3} alt="" className="lg:h-[200px] md:h-[150px] h-[120px] lg:w-[200px] md:w-[150px] w-[120px]"  data-aos="fade-up" />
              <img src={company4} alt="" className="lg:h-[200px] md:h-[150px] h-[120px] lg:w-[200px] md:w-[150px] w-[120px]"  data-aos="fade-up" />
            </div>
          </div>

          {/* Launched Services */}
          <div className=" flex justify-center md:my-28 my-16">
            <div className="lg:w-[80%] w-[90%]">
              <div className="">
                <h3 className="md:text-4xl text-3xl text-[#011c29] tracking-wide md:leading-[48px] leading-[40px] font-extrabold lg:w-[700px] w-full" data-aos="fade-up">
                  Launched Over 200 Projects – For All-Sized Businesses
                </h3>
              </div>
              <div className="mt-16 flex flex-wrap gap-10 md:justify-between justify-center">
                <div className="mt-4 font-medium w-[320px]" data-aos="fade-up">
                  <h4 className="text-xl font-medium tracking-wide">
                    For <br />{" "}
                    <span className="text-3xl font-semibold">Startups</span>
                  </h4>
                  <ul className="mt-4">
                    <li>
                      <i className="fa fa-check text-[coral] mr-2 mt-2"></i>
                      Accelerated Prototyping
                    </li>
                    <li>
                      <i className="fa fa-check text-[coral] mr-2 mt-2"></i>
                      MVP Development
                    </li>
                    <li>
                      <i className="fa fa-check text-[coral] mr-2 mt-2"></i>
                      Proof of Concept (POC) Creation
                    </li>
                  </ul>
                </div>
                <div className="mt-4 font-medium w-[320px]" data-aos="fade-up">
                  <h4 className="text-xl font-medium tracking-wide">
                    For <br />{" "}
                    <span className="text-3xl font-semibold">SMBs</span>
                  </h4>
                  <ul className="mt-4">
                    <li>
                      <i className="fa fa-check text-[coral] mr-2 mt-2"></i>
                      Tailored Software Development
                    </li>
                    <li>
                      <i className="fa fa-check text-[coral] mr-2 mt-2"></i>
                      Digital Marketing Solutions
                    </li>
                    <li>
                      <i className="fa fa-check text-[coral] mr-2 mt-2"></i>
                      Application Maintenance and Fixes
                    </li>
                  </ul>
                </div>
                <div className="mt-4 font-medium w-[320px]" data-aos="fade-up">
                  <h4 className="text-xl font-medium tracking-wide">
                    For <br />{" "}
                    <span className="text-3xl font-semibold">Enterprises</span>
                  </h4>
                  <ul className="mt-4">
                    <li>
                      <i className="fa fa-check text-[coral] mr-2 mt-2"></i>
                      Enterprise-Grade Software Solutions
                    </li>
                    <li>
                      <i className="fa fa-check text-[coral] mr-2 mt-2"></i>
                      Cloud Deployment Services
                    </li>
                    <li>
                      <i className="fa fa-check text-[coral] mr-2 mt-2"></i>
                      Comprehensive DevOps Support
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr />

          {/* Faqs */}
          <div className="lg:w-[80%] w-full mx-auto py-4 md:my-16 my-10 px-10">
            <div
              className="flex flex-col items-center mb-16"
            >
              <h2 className="lg:text-4xl text-3xl font-extrabold md:px-5 px-0" data-aos="fade-up">
                Frequently Asked Questions
              </h2>
              {/* <div className="mt-3">
            <div className="h-[2px] w-[300px] bg-[grey] relative">
              <div className="h-2 w-2 rounded-full bg-[#37a53f] absolute left-0 -top-[3px] head-animation"></div>
            </div>
          </div> */}
            </div>
            {faqs.map((faq, index) => (
              <div key={index} className="mb-4 my-10" data-aos="fade-up">
                <div className="flex items-center justify-between border-b border-gray-200 cursor-pointer">
                  <h2 className="text-md font-medium mb-3 tracking-wide">
                    {faq.question}
                  </h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 transform transition-transform duration-300"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    onClick={() =>
                      setFaqs((prevFaqs) =>
                        prevFaqs.map((item, i) =>
                          i === index ? { ...item, open: !item.open } : item
                        )
                      )
                    }
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 19a1 1 0 0 1-1-1V3a1 1 0 1 1 2 0v15a1 1 0 0 1-1 1zm-7-8a1 1 0 0 1-1-1V3a1 1 0 1 1 2 0v7a1 1 0 0 1-1 1zm14 0a1 1 0 0 1-1-1V3a1 1 0 1 1 2 0v7a1 1 0 0 1-1 1z"
                    />
                  </svg>
                </div>
                {faq.open && (
                  <p className="text-gray-700 text-base mt-4 tracking-wider">
                    {faq.answer}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </div>
  );
}
